jQuery(document).ready(function($) {
    if ($('html').hasClass('touchevents')) {
        $('.vc_tta.vc_general .vc_tta-panel-title > a, .vc_tta.vc_general .vc_tta-panel-title > a').off('click touchstart touchend');
    }
});


jQuery.noConflict();
jQuery(document).ready(function($) {


    // Preloader
    $(window).on('load', function() { // makes sure the whole site is loaded 
        $('.preloader-gif').fadeOut(); // will first fade out the loading animation 
        $('.preloader-slider').delay(350000).fadeOut('slow'); // will fade out the white DIV that covers the website. 
        $('.slider-principal').delay(350000).css({ 'overflow': 'visible' });
    });


    // on load
    $(window).on('load', function() {

        // Carousel 

        $('.slide-principal').owlCarousel({
            pagination: true,
            nav: false,
            loop: true,
            margin: 0,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }

        });


        $('.results').owlCarousel({
            //pagination: false,
            nav: true,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 2
                },
                992: {
                    items: 3
                },
                1200: {
                    items: 3
                },
            }
        });


        $('.slide-articles').owlCarousel({
            //pagination: false,
            nav: false,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 2
                },
                992: {
                    items: 2
                },
                1200: {
                    items: 2
                },
            }
        });




        $('.slide-projectes').owlCarousel({
            //pagination: false,
            nav: false,
            loop: false,
            dots: true,
            margin: 20,
            autoplay: false,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                576: {
                    items: 2
                },
                768: {
                    items: 2
                },
                992: {
                    items: 2
                },
                1200: {
                    items: 2
                },
            }
        });


        $('.relacionados').owlCarousel({
            pagination: true,
            nav: true,
            loop: false,
            margin: 30,
            autoplay: true,
            autoplayTimeout: 5000,
            autoplayHoverPause: true,
            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                },
                1000: {
                    items: 3
                }
            }

        });








        $(".owl-prev").html('<i class="icon-arrow-left"></i>');
        $(".owl-next").html('<i class="icon-arrow-right"></i>');


    });

    $(document).ready(function () {
      location.hash && $(location.hash + '.collapse').collapse('show');
    });

    // Datepicker
    $('.datepicker').datepicker({
        closeText: 'Tancar',
        prevText: '<i class="ei-arrow_carrot-left_alt"></i>',
        nextText: '<i class="ei-arrow_carrot-right_alt"></i>',
        currentText: 'Avui',
        monthNames: ['Gener', 'Febrer', 'Març', 'Abril', 'Maig', 'Juny', 'Juliol', 'Agost', 'Setembre', 'Octubre', 'Novembre', 'Desembre'],
        monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
        dayNames: ['diumenge', 'dilluns', 'dimarts', 'dimecres', 'dijous', 'divendres', 'dissabte'],
        dayNamesShort: ['dg.', 'dl.', 'dt.', 'dc.', 'dj.', 'dv.', 'ds.'],
        dayNamesMin: ['dg', 'dl', 'dt', 'dc', 'dj', 'dv', 'ds'],
        weekHeader: 'Sm',
        dateFormat: 'dd/mm/yy',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: '',
        changeMonth: true,
        changeYear: true,
    });

/*
$("#restricting").datepicker({ 
    yearRange: "-20:+0", // this is the option you're looking for
    showOn: "both", 
    buttonImage: "templates/images/calendar.gif", 
    buttonImageOnly: true 
});

$("#datepicker").datepicker({
    changeMonth: true,
    changeYear: true,
    yearRange: '-100:+0'
});
*/

    var windowHeight = $(window).height();

    // Bootstrap Auto-Hiding Navbar 
    $(".header").autoHidingNavbar();
    //$(".header").autoHidingNavbar({ showOnBottom: false, showOnUpscroll: true });

    
    // Highlight the top nav as scrolling occurs
    /*$(window).scroll(function() {
        if ($(document).scrollTop() > 250) {
            $('.header').addClass('shrink');
        } else {
            $('.header').removeClass('shrink');
        }
    });*/


    $(function() {
        //caches a jQuery object containing the header element
        var header = $(".header");
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll > 250) {
                header.addClass("shrink");
            } else {
                header.removeClass("shrink");
            }
        });
    });


   




    // Scroll
    $(".scroll-down").click(function() {
        //window.scrollBy(0, 500);
    });

    /*
    var $click = $('html,body'); 
    $(".scroll-down").on('click', function(event) { 
        $("HTML, BODY").animate({scrollTop: $click.height()}, 1000);
        event.preventDefault();
    }); 
    */



    // Anchor scroll
    var $root = $('html, body');
    $('a.anchor').click(function() {
        $root.animate({
            scrollTop: $( $.attr(this, 'href') ).offset().top - 110
        }, 1200);
        return false;
    });
  

   
   






    // Toast
    $('.toast').toast({

    });


    // Team members
    // Hover effect

    $('.team-member').on('click touchstart', function() {
        $('.team-member.active').removeClass('active');
        $(this).toggleClass('active');
        //return false;

    });


    $(document).on("click touchstart", function(e) {
        var clicked = $(e.target).closest('.team-members');
        if (clicked.length === 0) {
            $(".team-member.active").removeClass('active');
        }
    });




    $('.expand-link').on('click touchstart', function() {
        $('.expand-link.active').removeClass('active');
        $(this).toggleClass('active');
        //return false;

    });

/*
    $(document).on("click touchstart", function(e) {
        var clicked = $(e.target).closest('.honey');
        if (clicked.length === 0) {
            $(".expand-link.active").removeClass('active');
        }
    });

*/







    // Search
    $('.searchandfilter .postform').addClass('form-control');
    $('.searchandfilter input[type=text]').addClass('form-control');
    $('.searchandfilter input[type=submit]').addClass('btn btn-primary');

    // Contact
    $('.wpcf7-form .wpcf7-text').addClass('form-control');
    $('.wpcf7-textarea').addClass('form-control');
    $('.wpcf7-date').addClass('form-control');
    $('.wpcf7-submit').addClass('btn btn-primary');


    // Responsive iframes
    $("iframe[src*='youtube']​​​​​​​​​​​​​​​​").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
    $("iframe[src*='youtube']​​​​​​​​​​​​​​​​").addClass('embed-responsive-item');

    // Responsive iframes
    fluidvids.init({
      selector: ['iframe'],
      players: ['www.youtube.com', 'player.vimeo.com']
    });

    $(".video").wrap('<div class="embed-responsive embed-responsive-16by9"/>');
    $(".video").addClass('embed-responsive-item');



});